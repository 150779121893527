import { admin2 } from './instance'

export const GetShopBusinessTimeConfig = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/shopBusinessTime`,
  })
}

export const UpdateShopBusinessTimeConfig = async ({
  shopId,
  mode,
  customWeekConfigs,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/shopBusinessTime`,
    data: {
      mode,
      customWeekConfigs,
    },
  })
}
