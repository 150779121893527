<template>
  <div v-loading="!loaded">
    <div v-if="loaded" class="form-wrapper resource-edit">
      <ResourceItemSettings
        class="card-container wrapper"
        :configData="configData"
      />
      <ResourceReservationSettings
        class="card-container wrapper"
        :configData="configData"
      />
    </div>
    <PageFixedFooter
      :confirmLoading="loading"
      @cancel="onCancel"
      @confirm="onSubmit"
    />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import { useShop } from '@/use/shop'
import { CreateResource, FindResourceItem, UpdateResource } from '@/api/resource'
import { useRoute, useRouter } from 'vue-router/composables'
import FormsContext from './formsContext'
import ResourceItemSettings from './components/ResourceItemSettings.vue'
import ResourceReservationSettings from './components/ResourceReservationSettings.vue'
import notifyMessage from '@/config/notifyMessage'
import { get } from 'lodash'
import { useBaseForm } from '@/use/useForm'
export default defineComponent({
  name: 'ResourceEdit',
  components: {
    ResourceItemSettings,
    ResourceReservationSettings,
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { loading, formSubmit } = useBaseForm()
    const route = useRoute()
    const router = useRouter()
    const resourceId = computed(() => route.params.resourceId)
    const configData = ref({})
    const loaded = ref(false)

    const findResourceItem = async () => {
      const [res, err] = await FindResourceItem({
        shopId: shopId.value,
        id: resourceId.value,
      })

      if (err) {
        window.$message.error(err)
        return
      }
      configData.value = res
    }

    const submitData = computed(() => {
      const allFormData = FormsContext.composFormData()
      // 自訂義組裝最後要送出 api payload 的資料
      if (get(configData.value, 'id')) {
        return {
          shopId: shopId.value,
          id: get(configData.value, 'id'),
          ...allFormData,
        }
      }
      return {
        shopId: shopId.value,
        ...allFormData,
      }
    })

    const onCancel = () => {
      router.push({ name: 'ResourceSetting' })
    }
    const onSubmit = async () => {
      const allPass = await FormsContext.validateAll()
      if (!allPass) return

      let apiMethod = CreateResource
      let successMessage = notifyMessage.createSuccess
      if (get(configData.value, 'id')) {
        apiMethod = UpdateResource
        successMessage = notifyMessage.updateSuccess
      }
      const [, err] = await apiMethod(submitData.value)
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(successMessage)
      router.push({ name: 'ResourceSetting' })
    }

    onMounted(async () => {
      if (resourceId.value) await findResourceItem()
      loaded.value = true
    })
    return {
      configData,
      loading,
      FormsContext,
      router,
      // createResource,
      // updateResource,
      onCancel,
      onSubmit,
      resourceId,
      loaded,
    }
  },
})
</script>

<style lang="postcss" scoped>
.wrapper {
  padding: 20px !important;
  padding-left: 29px !important;
}
.resource-edit {
  display: grid;
  gap: 24px;
}

</style>
