import { admin, admin2 } from '@/api/instance'

const baseUrl = (shopId) => {
  return `/${shopId}/appointmentService`
}

//= > 創建服務
export const CreateService = async ({
  shopId,
  name,
  image,
  description,
  price,
  showTime,
  showPrice,
  bookingTime,
  order,
  isPublic,
  appointmentServiceAttaches,
  appointmentCategories,
  appointmentUnits,
  resourceItems,
  enableSubService,
  enableSubServiceClientHide,
  appointmentSubServices,
  requiredClassTicket,
  classTickets,
  enableAppointmentUnit,
  enableResourceItem,
  attachServiceSettings,
}) => {
  return await admin2({
    method: 'post',
    url: baseUrl(shopId),
    data: {
      name,
      image,
      description,
      price,
      showTime,
      showPrice,
      bookingTime,
      order,
      isPublic,
      appointmentServiceAttaches,
      appointmentCategories,
      appointmentUnits,
      resourceItems,
      enableSubService,
      enableSubServiceClientHide,
      appointmentSubServices,
      requiredClassTicket,
      classTickets,
      enableAppointmentUnit,
      enableResourceItem,
      attachServiceSettings,
    },
  })
}

//= > 取得服務
export const GetService = async ({ shopId, start, limit, name }) => {
  const res = await admin({
    method: 'get',
    url: baseUrl(shopId),
    params: {
      start,
      limit,
      name,
    },
  })

  return res.data
}

export const GetServiceNew = async ({ shopId, start, limit, name }) => {
  return await admin2({
    method: 'get',
    url: baseUrl(shopId),
    params: {
      start,
      limit,
      name,
    },
  })
}

//= > 取得服務總數
export const GetServiceCount = async ({ shopId, name }) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/count`,
    params: {
      name,
    },
  })

  return res
}

export const GetServiceCountNew = async ({ shopId, name }) => {
  return await admin2({
    method: 'get',
    url: `${baseUrl(shopId)}/count`,
    params: {
      name,
    },
  })
}

//= > 取得指定服務
export const FindService = async ({ shopId, id }) => {
  return await admin2({
    method: 'get',
    url: `${baseUrl(shopId)}/${id}`,
  })
}

//= > 更新服務
export const UpdateService = async ({
  shopId,
  id,
  name,
  image,
  description,
  price,
  showTime,
  showPrice,
  bookingTime,
  address,
  order,
  isPublic,
  appointmentServiceAttaches,
  appointmentCategories,
  appointmentUnits,
  resourceItems,
  enableSubService,
  enableSubServiceClientHide,
  appointmentSubServices,
  requiredClassTicket,
  classTickets,
  enableAppointmentUnit,
  enableResourceItem,
  attachServiceSettings,
}) => {
  return await admin2({
    method: 'put',
    url: `${baseUrl(shopId)}/${id}`,
    data: {
      name,
      image,
      description,
      price,
      showTime,
      showPrice,
      bookingTime,
      address,
      order,
      isPublic,
      appointmentServiceAttaches,
      appointmentCategories,
      appointmentUnits,
      resourceItems,
      enableSubService,
      enableSubServiceClientHide,
      appointmentSubServices,
      requiredClassTicket,
      classTickets,
      enableAppointmentUnit,
      enableResourceItem,
      attachServiceSettings,
    },
  })
}

//= > 刪除服務
export const DeleteService = async ({ shopId, id }) => {
  return await admin2({
    method: 'delete',
    url: `${baseUrl(shopId)}/${id}`,
  })
}

// --- 子項目 ---

export const UpdateSubService = async ({
  shopId,
  id,
  name,
  price,
  showPrice,
  bookingTime,
  showTime,
  order,
  allAppointmentUnit,
  appointmentUnits,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/appointmentSubServices/${id}`,
    data: {
      name,
      price,
      showPrice,
      bookingTime,
      showTime,
      order,
      allAppointmentUnit,
      appointmentUnits,
    },
  })
}

export const GetSubService = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/appointmentSubServices`,
  })
}

export const FindSubService = async ({ shopId, id }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/appointmentSubServices/${id}`,
  })
}
