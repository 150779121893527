<template>
  <div class="resource-item-settings">
    <div class="font-bold text-lg text-primary-100" style="margin-bottom: 16px">設備項目設定</div>

    <el-form ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <el-form-item label="啟用狀態" prop="enabled">
        <template slot="label">
          <FormItemTooltipLabel label="啟用狀態" :tooltipWidth="200">
            <ul class="tooltip-ul">
              <li>啟用狀態為「開啟」，則可於後台其他模組選擇此設備</li>
              <li>啟用狀態從「開啟」調整為「關閉」，將自動關閉已設定的排班時段。不影響已成立的預約訂單</li>
            </ul>
          </FormItemTooltipLabel>
        </template>
        <el-switch
          v-model="formData.enabled"
          active-text="開啟"
          inactive-text="關閉"
        />
      </el-form-item>
      <el-form-item label="圖片" prop="image">
        <UploadButton
          cyUploadBtn="resource-img-upload-btn"
          cyUploadedImg="resource-img-uploaded"
          :img="formData.avatar"
          :isAvatar="true"
          @change="imageChange"
        />
      </el-form-item>
      <el-form-item label="名稱" prop="name">
        <el-input v-model="formData.name" :maxlength="55" show-word-limit data-cy="name-input" placeholder="名稱" />
      </el-form-item>
      <el-form-item label="同時段可使用數量" prop="bookingMax" placeholder="請輸入數字">
        <el-input v-model.number="formData.bookingMax" type="number" data-cy="booking-max-input" />
      </el-form-item>
      <el-form-item label="排序" prop="order">
        <el-input v-model="formData.order" clearable data-cy="order-input" placeholder="請輸入排序" />
      </el-form-item>
      <el-form-item label="綁定服務類別" prop="appointmentServices">
        <template slot="label">
          <div class="inline-flex items-center" style="gap: 8px">
            <p>綁定服務項目</p>
            <el-tooltip placement="right">
              <div slot="content">
                需綁定服務項目，設備項目才會出現在預約選單內。
              </div>
              <span class="material-icons">help_outline</span>
            </el-tooltip>
          </div>
        </template>
        <ServiceSelect data-cy="unit-binding-select" :model.sync="formData.appointmentServices" checkResource multiple :showAll="true" />
      </el-form-item>
    </el-form>
    <ImageCropper
      v-if="modal.cropper"
      :image="formData.image"
      @uploaded="getCroppedImage"
      @close="modal.cropper = false"
    />
  </div>
</template>

<script>
import UploadButton from '@/components/Button/UploadButton.vue'
import ServiceSelect from '@/components/Select/ServicesSelect.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { defineComponent, computed, reactive, onMounted, watch, nextTick } from 'vue'
import { get } from 'lodash'
import { mappingSyncFormData, useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import FormsContext from '../formsContext'

export default defineComponent({
  name: 'ResourceItemSettings',
  components: { UploadButton, ServiceSelect, ImageCropper, FormItemTooltipLabel },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const { initFormData, formData, formRef } = useBaseForm()
    const modal = reactive({
      cropper: false,
    })

    initFormData({
      enabled: false,
      image: null,
      avatar: null,
      name: '',
      bookingMax: null,
      appointmentServices: [],
      order: 100,
    })

    const formRules = computed(() => {
      const rules = {
        enabled: [noEmptyRules()],
        name: [noEmptyRules()],
        bookingMax: [noEmptyRules()],
        order: [noEmptyRules()],
      }
      return rules
    })

    const syncFormData = (data) => {
      mappingSyncFormData({ Image: 'avatar', AppointmentServices: 'appointmentServices' }, data, formData)
    }

    const compactData = computed(() => {
      const data = {
        enabled: get(formData, 'enabled'),
        image: get(formData, 'avatar.id'),
        // avatar: get(formData, 'avatar'),
        name: get(formData, 'name'),
        bookingMax: get(formData, 'bookingMax'),
        order: get(formData, 'order'),
        appointmentServices: formData.appointmentServices ? formData.appointmentServices.map(item => item.id) : [],
      }
      return data
    })

    watch(compactData, () => {
      FormsContext.setFormData('formRef1', { ...compactData.value })
    })

    const getCroppedImage = (data) => {
      formData.avatar = data
      modal.cropper = false
    }

    const imageChange = (img) => {
      formData.image = img
      modal.cropper = true
    }

    onMounted(async () => {
      await nextTick()
      FormsContext.setFormRef('formRef1', formRef.value)
      if (get(props.configData, 'id')) syncFormData(props.configData)
    })

    return {
      modal,
      formRef,
      formData,
      formRules,
      getCroppedImage,
      imageChange,
    }
  },
})
</script>

<style lang="postcss" scoped>

.sub-title {
  font-size: 12px;
  color: var(--gray-80);
  font-weight: 500;
  margin-bottom: 32px;
}
.tooltip-ul {
  list-style: disc;
  padding-left: 16px;
}
</style>
